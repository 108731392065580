
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { MaterialShadeInfos } from "@/domain";

import { IMaterialShadeService } from "@/services";

@Component({
  components: {
    UpdateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class MaterialShadeUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: MaterialShadeInfos;

  @Prop({ required: true })
  forbiddenValues!: string[];

  get materialShadeService() {
    return this.container.resolve<IMaterialShadeService>(
      S.MATERIAL_SHADE_SERVICE
    );
  }

  loading: boolean = false;
  shadeValue: string | null = null;

  mounted() {
    this.shadeValue = this.item.shadeValue;
  }

  async update() {
    try {
      this.loading = true;
      const updatedShade = await this.materialShadeService.update(
        this.item.id,
        {
          shadeValue: this.shadeValue!,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", updatedShade);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(shadeValue: string) {
    return (
      _.findIndex(
        this.forbiddenValues,
        (v) => v.toUpperCase() == shadeValue.toUpperCase()
      ) != -1 && this.item.shadeValue.toUpperCase() != shadeValue.toUpperCase()
    );
  }
}
